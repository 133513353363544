import React, {useEffect, useState} from "react";
import {Button, Col, Modal, Row, Spinner} from "react-bootstrap";
import {Form as FormikForm, FormField, SubmitButton} from "../../../../components/forms"
import * as yup from 'yup';
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from 'react-bootstrap-table2-editor';
import moment from "moment";
import {uid} from "uid";

// for pdf
import {Document, Font, Image, Page, StyleSheet, Text, usePDF, View} from '@react-pdf/renderer';
import {DataTableCell, Table, TableBody, TableCell, TableHeader} from "@david.kucsai/react-pdf-table";
import RobotoRegular from "../../../../assets/fonts/Roboto-Regular.ttf"
import RobotoBold from "../../../../assets/fonts/Roboto-Bold.ttf"
import RobotoItalic from "../../../../assets/fonts/Roboto-Italic.ttf"
import RobotoBoldItalic from "../../../../assets/fonts/Roboto-BoldItalic.ttf"
import Logo from "../../../../assets/img/Nordomatic_logo_POS.png";
import {User} from "react-feather";

/**
 * Represents the validation schema for a variable.
 *
 * @type {Object}
 * @property {String} reference - The reference value. Required.
 * @property {String} customer_name - The customer name value. Required.
 * @property {String} adress - The address value. Required.
 * @property {String} zipcode - The zip code value. Required.
 *                              Must match the pattern /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i.
 * @property {String} city - The city name value. Required.
 * @property {String} contact - The contact person value. Required.
 */
const schema = yup.object().shape({
    reference: yup.string().required("Referentie is niet ingevuld"),
    customer_name: yup.string().required("Klantnaam is niet ingevuld"),
    adress: yup.string().required("Adres is niet ingevuld"),
    zipcode: yup.string().required("postcode is niet ingevuld"),
    city: yup.string().required("Stad naam is niet ingevuld"),
    contact: yup.string().required("Contactpersoon is niet ingevuld")
});

/**
 * Method to handle the Product Orders Pakbon Modal.
 *
 * @return {React.Component} The rendered Product Orders Pakbon Modal.
 */
function ProductOrdersPakbonModal() {
    const [show, setShow] = useState(false);
    const [data, setData] = useState([{
        product_order_detail_id: uid(),
        product_name: "",
        product_order_detail_amount: 1,
        product_description: ""

    }])
    const [printReady, setPrintReady] = useState(false)
    const [stateValues, setValues] = useState({
        reference: '',
        customer_name: '',
        adress: '',
        zipcode: '',
        city: '',
        contact: '',
    })
    const [instance, updateInstance] = usePDF({document: <MyDoc values={stateValues} data={data}/>});
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [columns] = useState(
        [
            {
                dataField: "product_order_detail_id",
                hidden: true
            },
            {
                dataField: "product_name",
                text: "Product",
                formatter: (c, r) => {
                    if (c === "DIV") return r.product_order_detail_comment
                    return c
                }
            },
            {
                dataField: "product_description",
                text: "Omschrijving",
            },
            {
                dataField: "product_order_detail_amount",
                text: "Aantal",
            },
        ]
    )

    const handleSubmit = (values) => {
        setValues(values)
    }

    useEffect(() => {
        if (stateValues.customer_name) {
            updateInstance()
            setPrintReady(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateValues])

    const cellEdit = () => cellEditFactory({
        mode: 'click',
    })
    const addToTable = () => {
        setData([...data, {
            product_order_detail_id: uid(),
            product_name: "",
            product_order_detail_amount: null,
            product_description: ""
        }])
    }


    return (
        <>
            <Button onClick={handleShow} className={"p-2 m-1"} variant={"warning"} style={{height: 90, width: 90}}>
                <User size={50}/>
                <div>Pakbon</div>
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Pakbon maken
                    </Modal.Title>
                </Modal.Header>
                <FormikForm
                    initialValues={stateValues}
                    // Hier is het validatieschema van alle velden
                    validationSchema={schema}
                    onSubmit={handleSubmit}
                >
                    <Modal.Body>
                        <Col className={"m-4"}>
                            <Row>
                                Referentie
                                <hr/>
                            </Row>

                            <Row>
                                <Col>
                                    <FormField name={"reference"}
                                               label={"Referentie"}
                                               className={"mb-3"}
                                               control={"input"}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                VERZEND DETAILS
                                <hr/>
                            </Row>
                            <Row>
                                <Col>
                                    <FormField name={"customer_name"}
                                               label={"Klantnaam"}
                                               className={"mb-3"}
                                               control={"input"}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormField name={"adress"}
                                               label={"Adres"}
                                               className={"mb-3"}
                                               control={"input"}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <FormField name={"zipcode"}
                                               label={"Postcode"}
                                               className={"mb-3"}
                                               control={"input"}
                                    />
                                </Col>
                                <Col>
                                    <FormField name={"city"}
                                               label={"Stad"}
                                               className={"mb-3"}
                                               control={"input"}
                                    />

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormField name={"contact"}
                                               label={"Contactpersoon"}
                                               className={"mb-3"}
                                               control={"input"}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Row className={"mb-1"}><Col lg={11}></Col><Col><Button onClick={addToTable}
                                                                                className={"float-end"}>+</Button></Col></Row>
                        <BootstrapTable
                            cellEdit={cellEdit()}
                            columns={columns}
                            keyField={"product_order_detail_id"}
                            data={data}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Annuleren
                        </Button>
                        {!printReady ? <SubmitButton title={"Opslaan"} variant="primary"/> :
                            instance.loading ? <Button><Spinner animation="border" variant="light" size="sm"/></Button>
                                : <a href={instance.url} download={`${stateValues.reference} PAKBON.pdf`}>
                                    <Button onClick={() => setPrintReady(false)} variant={"success"}>Download</Button>
                                </a>
                        }
                    </Modal.Footer>
                </FormikForm>
            </Modal>
        </>
    );
}

export default ProductOrdersPakbonModal;


/**
 * MyDoc is a functional component that generates a PDF document using
 * the react-pdf library.
 *
 * @param {object} values - An object containing values such as customer name, address, etc.
 * @param {array} data - An array of objects containing data for the table in the document.
 * @returns {ReactElement} - A PDF document element generated by react-pdf.
 */
const MyDoc = ({values, data}) => {
    return (<Document>
            <Page style={styles.body}>
                <View style={{flexDirection: "row", justifyContent: "space-between"}}>
                    <Image style={styles.yconLogo} src={Logo}/>
                </View>
                <View style={{flexDirection: "row", justifyContent: "space-between", marginBottom: 20, marginTop: 80}}>
                    <View style={{marginLeft: 20}}>
                        <Text style={styles.title}>AFLEVERADRES</Text>
                        <View style={{flexDirection: "row"}}>
                            <View style={{marginRight: 15}}>
                                <Text style={styles.textSmallAdres}>ADRES</Text>
                                <Text style={styles.textSmall}>CONTACT</Text>
                                <Text style={styles.textSmall}>DATUM</Text>
                                {values.comment ? <Text style={styles.textSmall}>OPMERKING</Text> : null}
                            </View>
                            <View style={{marginRight: 15}}>
                                <Text style={styles.textSmallAdres}>:</Text>
                                <Text style={styles.textSmall}>:</Text>
                                <Text style={styles.textSmall}>:</Text>
                                {values.comment ? <Text style={styles.textSmall}>:</Text> : null}
                            </View>
                            {values.customer_name ? <View>
                                <Text style={styles.textSmall}>{values?.customer_name}</Text>
                                <Text style={styles.textSmall}>{values?.adress}</Text>
                                <Text style={styles.textSmall}>{`${values?.zipcode} ${values?.city}`}</Text>
                                <Text style={styles.textSmall}>{values?.contact}</Text>
                                <Text style={styles.textSmall}>{moment().format("DD-MM-YYYY")}</Text>
                                {values.comment ? <Text style={styles.textSmall}>{values.comment}</Text> : null}
                            </View> : null}
                        </View>
                    </View>
                    <View style={{width: 200}}>
                        <Text style={styles.textSmallRightBold}>Nordomatic Belgium BV</Text>
                        <Text style={styles.textSmallRight}>Herentalsesteenweg 81A</Text>
                        <Text style={styles.textSmallRight}>2280 Grobbendonk, Belgium</Text>
                        <Text style={styles.textSmallRight}>BTW nr: BE0826403970</Text>
                        <Text style={styles.textSmallRight}>IBAN: BE76 0016 1290 6195</Text>
                        <Text style={styles.textSmallRight}>(+32) 1449 0280</Text>
                        <Text style={styles.textSmallRight}>sm-be-info@nordomatic.com</Text>
                    </View>
                </View>
                <View style={{
                    borderTop: "1px solid black",
                    borderBottom: "1px solid black",
                    paddingVertical: 2,
                    alignContent: "center",
                    justifyContent: "center",
                    marginBottom: 20
                }}>
                    <Text style={styles.pageTitle}> PAKBON
                        - {values.reference} </Text>
                </View>
                <View>
                    <Table style={styles.tableStyle}
                           data={data}>
                        <TableHeader style={styles.tableStyle} includeTopBorder={false} includeRightBorder={false}
                                     includeLeftBorder={false}>
                            <TableCell style={styles.tableStyleHeader} weighting={0.3}>
                                PRODUCT
                            </TableCell>
                            <TableCell style={styles.tableStyleHeader} weighting={0.6}>
                                BESCHRIJVING
                            </TableCell>
                            <TableCell style={styles.tableStyleHeader} weighting={0.1}>
                                QTY
                            </TableCell>
                        </TableHeader>
                        <TableBody includeTopBorder={false} includeRightBorder={false} includeLeftBorder={false}
                                   includeBottomBorder={false}>
                            <DataTableCell style={styles.tableStyleCell}
                                           weighting={0.3}
                                           getContent={(r) => r.product_name === "DIV" ? r.product_order_detail_comment : r.product_name}
                            />
                            <DataTableCell style={styles.tableStyleCell}
                                           weighting={0.6}
                                           getContent={(r) => r.product_description}
                            />
                            <DataTableCell style={styles.tableStyleCell}
                                           weighting={0.1}
                                           getContent={(r) => r.product_order_detail_amount}
                            />
                        </TableBody>
                    </Table>
                </View>
            </Page>
        </Document>
    )
};

Font.register({
    family: 'Roboto', fonts: [
        {src: RobotoRegular}, // font-style: normal, font-weight: normal
        {src: RobotoItalic, fontStyle: 'italic'},
        {src: RobotoBoldItalic, fontStyle: 'italic-bold'},
        {src: RobotoBold, fontStyle: 'bold'},
    ]
});

Font.registerHyphenationCallback(word => [word]);

/**
 * Represents a collection of styles for formatting elements in a document.
 *
 * @typedef {object} Styles
 * @property {object} body - The style for the body section of the document.
 * @property {number} body.paddingTop - The top padding of the body section.
 * @property {number} body.paddingBottom - The bottom padding of the body section.
 * @property {number} body.paddingHorizontal - The horizontal padding of the body section.
 * @property {number} body.fontSize - The font size of the body section.
 * @property {object} pageTitle - The style for the page title.
 * @property {string} pageTitle.fontFamily - The font family of the page title.
 * @property {string} pageTitle.fontStyle - The font style of the page title.
 * @property {number} pageTitle.fontSize - The font size of the page title.
 * @property {string} pageTitle.textAlign - The text alignment of the page title.
 * @property {object} title - The style for a title element.
 * @property {string} title.fontFamily - The font family of the title element.
 * @property {string} title.fontStyle - The font style of the title element.
 * @property {number} title.fontSize - The font size of the title element.
 * @property {number} title.marginBottom - The bottom margin of the title element.
 * @property {object} titleRight - The style for a right-aligned title element.
 * @property {string} titleRight.fontFamily - The font family of the right-aligned title element.
 * @property {string} titleRight.fontStyle - The font style of the right-aligned title element.
 * @property {number} titleRight.fontSize - The font size of the right-aligned title element.
 * @property {string} titleRight.textAlign - The text alignment of the right-aligned title element.
 * @property {number} titleRight.marginBottom - The bottom margin of the right-aligned title element.
 * @property {object} subTitle - The style for a subtitle element.
 * @property {string} subTitle.fontFamily - The font family of the subtitle element.
 * @property {string} subTitle.fontStyle - The font style of the subtitle element.
 * @property {number} subTitle.fontSize - The font size of the subtitle element.
 * @property {string} subTitle.textAlign - The text alignment of the subtitle element.
 * @property {object} textSmall - The style for a small text element.
 * @property {string} textSmall.fontFamily - The font family of the small text element.
 * @property {number} textSmall.fontSize - The font size of the small text element.
 * @property {number} textSmall.lineHeight - The line height of the small text element.
 * @property {object} textSmallRight - The style for a right-aligned small text element.
 * @property {string} textSmallRight.fontFamily - The font family of the right-aligned small text element.
 * @property {number} textSmallRight.fontSize - The font size of the right-aligned small text element.
 * @property {string} textSmallRight.textAlign - The text alignment of the right-aligned small text element.
 * @property {number} textSmallRight.lineHeight - The line height of the right-aligned small text element.
 * @property {object} textSmallRightBold - The style for a right-aligned bold small text element.
 * @property {string} textSmallRightBold.fontFamily - The font family of the right-aligned bold small text element.
 * @property {string} textSmallRightBold.fontStyle - The font style of the right-aligned bold small text element.
 * @property {number} textSmallRightBold.fontSize - The font size of the right-aligned bold small text element.
 * @property {string} textSmallRightBold.textAlign - The text alignment of the right-aligned bold small text element.
 * @property {number} textSmallRightBold.lineHeight - The line height of the right-aligned bold small text element.
 * @property {object} textSmallAdres - The style for a right-aligned small text element with increased line height.
 * @property {string} textSmallAdres.fontFamily - The font family of the right-aligned small text element with increased line height.
 * @property {number} textSmallAdres.fontSize - The font size of the right-aligned small text element with increased line height.
 * @property {string} textSmallAdres.textAlign - The text alignment of the right-aligned small text element with increased line height.
 * @property {number} textSmallAdres.lineHeight - The line height of the right-aligned small text element with increased line height.
 * @property {object} tableStyle - The style for a table element.
 * @property {number} tableStyle.fontSize - The font size of the table element.
 * @property {string} tableStyle.justifyContent - The alignment of the content within the table element.
 * @property {number} tableStyle.marginBottom - The bottom margin of the table element.
 * @property {object} tableStyleCell - The style for a cell within a table element.
 * @property {number} tableStyleCell.fontSize - The font size of the cell within a table element.
 * @property {string} tableStyleCell.justifyContent - The alignment of the content within the cell within a table element.
 * @property {number} tableStyleCell.padding - The padding of the cell within a table element.
 * @property {string} tableStyleCell.borderRightColor - The right border color of the cell within a table element.
 * @property {string} tableStyleCell.borderBottom - The bottom border of the cell within a table element.
 * @property {object} tableStyleHeader - The style for a header cell within a table element.
 * @property {number} tableStyleHeader.fontSize - The font size of the header cell within a table element.
 * @property {string} tableStyleHeader.justifyContent - The alignment of the content within the header cell within a table element.
 * @property {number} tableStyleHeader.padding - The padding of the header cell within a table element.
 * @property {string} tableStyleHeader.borderRightColor - The right border color of the header cell within a table element.
 * @property {object} yconLogo - The style for the Ycon logo.
 * @property {number} yconLogo.top - The top position of the Ycon logo.
 * @property {number} yconLogo.width - The width of the Ycon logo.
 * @property {string} yconLogo.height - The height of the Ycon logo.
 */
const styles = StyleSheet.create(
    {
        body: {
            paddingTop: 35,
            paddingBottom: 65,
            paddingHorizontal: 40,
            fontSize: 10
        },
        pageTitle: {
            fontFamily: "Roboto",
            fontStyle: "bold",
            fontSize: 16,
            textAlign: "center",
        },
        title: {
            fontFamily: "Roboto",
            fontStyle: "bold",
            fontSize: 12,
            marginBottom: 5,
        },
        titleRight: {
            fontFamily: "Roboto",
            fontStyle: "bold",
            fontSize: 12,
            textAlign: "right",
            marginBottom: 5
        },
        subTitle: {
            fontFamily: "Roboto",
            fontStyle: "bold",
            fontSize: 11,
            textAlign: "right",
        },
        textSmall: {
            fontFamily: "Roboto",
            fontSize: 9,
            lineHeight: 1.3,
        },
        textSmallRight: {
            fontFamily: "Roboto",
            fontSize: 9,
            textAlign: "right",
            lineHeight: 1.3,
        },
        textSmallRightBold: {
            fontFamily: "Roboto",
            fontStyle: "bold",
            fontSize: 9,
            textAlign: "right",
            lineHeight: 1.3,
        },
        textSmallAdres: {
            fontFamily: "Roboto",
            fontSize: 9,
            textAlign: "right",
            lineHeight: 3.9,
        },
        tableStyle: {
            fontSize: 9,
            justifyContent: "start",
            marginBottom: 20,
        },
        tableStyleCell: {
            fontSize: 9,
            justifyContent: "start",
            padding: 5,
            borderRightColor: "#ffffff",
            borderBottom: "1 solid #cccccc"
        },
        tableStyleHeader: {
            fontSize: 9,
            justifyContent: "start",
            padding: 5,
            borderRightColor: "#ffffff"
        },
        yconLogo: {top: 0, width: "auto", height: 50, marginBottom: 50}

    }
)