import React, {useEffect, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {Form as FormikForm, FormField, FormFieldSelect, SubmitButton} from "../../../../components/forms/index"
import * as Yup from 'yup';
import projectsApi from "../../../../api/projects";
import {Edit2, Plus} from "react-feather";
import supplierApi from "../../../../api/suppliers";
import {useAuth0} from "@auth0/auth0-react";
import moment from "moment";
import productsApi from "../../../../api/products";
import {useNavigate} from "react-router-dom";
import notyfApi from "../../../../utils/notyfApi";
import AppFormFieldAddressOrder from "../../../../components/forms/FormFieldAddressOrder";

/**
 * Opens a modal to add or edit an order.
 *
 * @param {object} props - The properties for the AddOrderModal component.
 * @param {object} props.exists - The existing order data if editing an order.
 * @param {function} props.reload - The function to reload the order data.
 *
 * @return {JSX.Element} - The rendered AddOrderModal component.
 */
function AddOrderModal({exists, reload}) {
    const {user} = useAuth0();
    const navigate = useNavigate()
    const [show, setShow] = useState(false);
    const [projects, setProjects] = useState([]);
    const [projectsDefault, setProjectsDefault] = useState();
    const [sups, setSups] = useState([]);
    const [supsDefault, setSupsDefault] = useState();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        getProjects()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getProjects = async () => {
        const res = await projectsApi.getAllProjects("active");
        let tempProjects = []
        for (let project of res.data) {
            if (exists?.product_order_project_id === project.project_id) {
                setProjectsDefault({
                    value: project.project_id,
                    label: `${project.project_code} ${project.project_name}`
                })
            }
            tempProjects.push({
                value: project.project_id,
                label: `${project.project_code} ${project.project_name}`
            })
        }
        const resSup = await supplierApi.getAllSuppliers()
        let tempSups = []
        for (let sup of resSup.data) {
            if (exists?.product_supplier_name === sup.product_supplier_name) {
                setSupsDefault({
                    value: sup.product_supplier_id,
                    label: sup.product_supplier_name
                })
            }
            tempSups.push({
                value: sup.product_supplier_id,
                label: sup.product_supplier_name
            })
        }
        setSups(tempSups)
        setProjects(tempProjects)
    };

    const handleSubmit = async (values, {setSubmitting}) => {
        setSubmitting(false);
        values.product_order_project_id = parseInt(values.product_order_project_id)
        values.product_order_supplier = parseInt(values.product_order_supplier)
        if (exists) {
            const res = await productsApi.editExistingProductOrder(exists.product_order_id, values)
            if (!res.ok) return notyfApi.error("Error " + res.status)
            reload()
            handleClose()
        } else {
            const res = await productsApi.postNewProductOrder(values)
            if (res.ok) navigate(`/order/${res.data.product_order_id}`)
        }
    }
    if (exists && !projectsDefault && !supsDefault) return <></>
    return (
        <>
            {exists ? <Edit2 className={"float-end cursor-pointer"} onClick={() => setShow(!show)}/> :
                <Button onClick={handleShow} className={"p-1 m-1"} style={{height: 90, width: 90}}> <Plus size={50}/>
                    <div>Bestellingen</div>
                </Button>}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {exists ? "Order aanpassen" : "Nieuwe Order"}
                    </Modal.Title>
                </Modal.Header>
                <FormikForm
                    initialValues={{
                        product_order_status: exists?.product_order_status || "incompleet",
                        product_order_order_date: exists?.product_order_order_date || moment().format("YYYY-MM-DD"),
                        product_order_purchaser: exists?.product_order_purchaser || user.sub,
                        product_order_supplier: exists?.product_order_supplier || null,
                        product_order_address: exists?.product_order_address || "Herentalse Steenweg 81a",
                        product_order_postcode: exists?.product_order_postcode || "2280",
                        product_order_city: exists?.product_order_city || "Grobbendonk",
                        product_order_contact: exists?.product_order_contact || user.name,
                        product_order_complete: exists?.product_order_complete || false,
                        product_order_customer: exists?.product_order_customer || "Nordomatic Belgium BVBA",
                        product_order_email: exists?.product_order_email || user.email,
                        product_order_priority: exists?.product_order_priority || "",
                        product_order_project_id: exists?.product_order_project_id || null,
                    }}

                    // Hier is het validatieschema van alle velden
                    validationSchema={Yup.object({
                        product_order_project_id: Yup.number().required('Project moet ingevuld worden').nullable(),
                        product_order_supplier: Yup.number().required('Leverancier moet ingevuld worden').nullable(),
                        product_order_customer: Yup.string().required('Klantnaam moet ingevuld worden'),
                        product_order_address: Yup.string().required('Adres moet ingevuld worden'),
                        product_order_postcode: Yup.string().required('Postcode moet ingevuld worden'),
                        product_order_city: Yup.string().required('Stad moet ingevuld worden'),
                        product_order_contact: Yup.string().required('Contactpersoon moet ingevuld worden'),
                    })}
                    onSubmit={handleSubmit}
                >
                    <Modal.Body>
                        <h3>Algemeen</h3>
                        <FormFieldSelect
                            name={"product_order_project_id"}
                            label={"Project:"}
                            width={"100%"}
                            placeholder="Vul de productnaam hier in"
                            className={"mb-3"}
                            control={"input"}
                            options={projects}
                            defaultValue={projectsDefault}
                        />
                        <FormFieldSelect name={"product_order_supplier"}
                                         label={"Leverancier:"}
                                         width={"100%"}
                                         placeholder="Vul de productnaam hier in"
                                         className={"mb-3"}
                                         control={"input"}
                                         as={"select"}
                                         options={sups}
                                         defaultValue={supsDefault}
                        />
                        <FormField name={"product_order_priority"}
                                   label={"Prioriteit:"}
                                   width={"100%"}
                                   placeholder="Vul de productnaam hier in"
                                   className={"mb-3"}
                                   control={"input"}
                                   as={"select"}
                        >
                            <option value={""}>Selecteer een prioriteit</option>
                            <option value={"laag"}>laag</option>
                            <option value={"middel"}>normaal</option>
                            <option value={"hoog"}>hoog</option>
                        </FormField>
                        <hr/>
                        <h3>Lever adres</h3>
                        <FormField name={"product_order_customer"}
                                   label={"Klantnaam"}
                                   width={"100%"}
                                   className={"mb-3"}
                        />
                        <FormField name={"product_order_contact"}
                                   width={"100%"}
                                   control={"input"}
                                   label={"Contactpersoon:"}
                                   className={"mb-3"}
                        />
                        <FormField name={"product_order_email"}
                                   width={"100%"}
                                   control={"input"}
                                   label={"Email:"}
                        />
                        <AppFormFieldAddressOrder names={{
                            street: "product_order_address",
                            city: "product_order_city",
                            zip: "product_order_postcode",
                        }} label={"Adres"}/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Annuleren
                        </Button>
                        <SubmitButton title={"Opslaan"} variant="primary" onClick={() => setTimeout(() => {
                            handleClose();
                        }, 100)}/>
                    </Modal.Footer>
                </FormikForm>
            </Modal>
        </>
    );
}

export default AddOrderModal;
