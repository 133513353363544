import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import useSidebar from "../../hooks/useSidebar";
import SidebarNav from "./SidebarNav";
import logo1 from "../../assets/img/Nordomatic_logo_NEG.png"
import logo2 from "../../assets/img/Nordomatic_logo_POS.png"
import logo3 from "../../assets/img/5th_element_1.png"
import useTheme from "../../hooks/useTheme";

/**
 * Sidebar component represents the navigation menu sidebar.
 * @param {Object} items - The items to display in the navigation menu.
 * @returns {JSX.Element} The rendered sidebar component.
 */
const Sidebar = ({items}) => {
    const {isOpen} = useSidebar();
    const {behavior} = useSidebar();
    const {theme} = useTheme();
    let actualLogo;
    let inlineStyleForLogo;

    if (behavior === "compact") {
        actualLogo = logo3;
        inlineStyleForLogo = "0.75rem 0.85rem";
    } else if (theme === "light") {
        actualLogo = logo2;
        inlineStyleForLogo = "1.15 1.5rem 1.15rem 1.5rem";
    } else if (theme === "dark" || theme === "colored" || theme === "default") {
        actualLogo = logo1;
        inlineStyleForLogo = "1.15 1.5rem 1.15rem 1.5rem";
    } else {
        actualLogo = logo2;
        inlineStyleForLogo = "1.15 1.5rem 1.15rem 1.5rem";
    }

    return (
        <nav className={`sidebar ${!isOpen ? "collapsed" : ""}`}>
            <div className="sidebar-content">
                <PerfectScrollbar>
                    <a className="sidebar-brand" style={{padding: inlineStyleForLogo}} href="/">
                        <img src={actualLogo} alt={"Het y-con logo"}/>
                    </a>

                    <SidebarNav items={items}/>
                </PerfectScrollbar>
            </div>
        </nav>
    );
};

export default Sidebar;
