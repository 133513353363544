import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {Card, Col, Row} from "react-bootstrap";
import {useAuth0} from "@auth0/auth0-react";
import illustration from "../../../assets/img/illustrations/customer-support.png";
import TaskTable from "../../components/dashboard/TaskTable";
import PlanningTable from "../../components/dashboard/PlanningTable";
import {EuroSymbol, Work} from "@mui/icons-material";
import dashboardApi from "../../../api/dashboard";
import {euroFormat} from "../../../utils/usefullFunctions";
import GuardWrapper from "../../../components/guards/GuardWrapper";
import notyf from "../../../utils/notyfApi";
import {NavLink} from "react-router-dom";
import RevenueDashboard from "../../components/dashboard/RevenueDashboard";
import ProductivityDashboard from "../../components/dashboard/ProductivityDashboard";
import BarChartForTotals from "../../charts/Chartjs/BarChartForTotals";
import PieForTotals from "../../charts/Chartjs/PieChartForTotals";

const UserDashboard = () => {
    const {user} = useAuth0()
    const [general, setGeneral] = useState({})

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        const res = await dashboardApi.getGeneral()
        if (!res.ok) return notyf.error("Error " + res.status)
        setGeneral(res.data)
    }

    return (
        <React.Fragment>
            <Helmet title="Overzicht"/>
            <Row>
                <Col md="6" xl className="d-flex">
                    <Card className="flex-fill">
                        <Card.Body className="p-0 d-flex flex-fill">
                            <Row className="g-0 w-100">
                                <Col xs="6">
                                    <div className="illustration-text p-3 m-1">
                                        <h4 className="illustration-text">
                                            Welkom {user.name}
                                        </h4>
                                    </div>
                                </Col>
                                <Col xs={6} className="align-self-end text-end">
                                    <img
                                        src={illustration}
                                        alt="Customer Support"
                                        className="img-fluid illustration-img"
                                    />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <GuardWrapper role={"read_manager:projects"}>
                    <Col md="6" xl className="d-flex">
                        <Card className="flex-fill">
                            <Card.Body className=" py-4">
                                <div className="d-flex align-items-start">
                                    <div className="flex-grow-1">
                                        <h3 className="mb-2">{euroFormat(general.sales_offer_amount)}</h3>
                                        <p className="mb-2">Totaal binnengehaald afgelopen maand</p>
                                    </div>
                                    <div className="d-inline-block ms-3">
                                        <div className="stat">
                                            <NavLink to={"/offer_overview"}>
                                                <EuroSymbol className="align-middle text-success"/>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </GuardWrapper>
                <Col md="6" xl className="d-flex">
                    <Card className="flex-fill">
                        <Card.Body className=" py-4">
                            <div className="d-flex align-items-start">
                                <div className="flex-grow-1">
                                    <h3 className="mb-2">{general.project_amount}</h3>
                                    <p className="mb-2">Actieve projecten</p>
                                </div>
                                <div className="d-inline-block ms-3">
                                    <div className="stat">
                                        <NavLink to={"/project_overview"}>
                                            <Work className="align-middle text-success"/>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {/*<GuardWrapper role={"admin:admin"}>*/}
            {/*    <Row>*/}
            {/*        <Col xs={6}>*/}
            {/*            <div>*/}
            {/*                <PieForTotals/>*/}
            {/*            </div>*/}
            {/*        </Col>*/}
            {/*        <Col xs={6}>*/}
            {/*            <div className={"d-flex"}>*/}
            {/*                <RevenueDashboard/>*/}
            {/*            </div>*/}

            {/*        </Col>*/}
            {/*    </Row>*/}
            {/*</GuardWrapper>*/}


            {/*<GuardWrapper role={"admin:admin"}>*/}
            {/*    <Row>*/}
            {/*        <Col xs={6}>*/}
            {/*            <div>*/}
            {/*                <BarChartForTotals/>*/}
            {/*            </div>*/}
            {/*        </Col>*/}
                    {/*<Col style={{overflowY: "hidden"}}>*/}
                    {/*    <div>*/}
                    {/*        <ProductivityDashboard/>*/}
                    {/*    </div>*/}
                    {/*</Col>*/}
            {/*    </Row>*/}
            {/*</GuardWrapper>*/}

            <Row>
                <TaskTable/>
                <GuardWrapper role={"read:offers"}><PlanningTable/></GuardWrapper>
            </Row>
        </React.Fragment>
    )
};

export default UserDashboard;
