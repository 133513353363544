import React, { useState} from "react";
import {Button, Form, Modal, Spinner} from "react-bootstrap";

// for pdf
import {Page, Text, Image, View, Font, Document, StyleSheet, usePDF} from '@react-pdf/renderer';
import {DataTableCell, Table, TableBody, TableCell, TableHeader} from "@david.kucsai/react-pdf-table";
import RobotoRegular from "../../../../assets/fonts/Roboto-Regular.ttf"
import RobotoBold from "../../../../assets/fonts/Roboto-Bold.ttf"
import RobotoItalic from "../../../../assets/fonts/Roboto-Italic.ttf"
import RobotoBoldItalic from "../../../../assets/fonts/Roboto-BoldItalic.ttf"
import moment from "moment";
import Logo from "../../../../assets/img/Nordomatic_logo_POS.png"
import {euroFormat} from "../../../../utils/usefullFunctions";

/**
 * Opens a modal to create an orderbon.
 *
 * @param {object} order - The order object.
 * @param {array} orderDetailsList - The list of order details.
 * @returns {JSX.Element} - The modal component to create an orderbon.
 */
function OrderBonModal({order, orderDetailsList}) {
    const [show, setShow] = useState(false);
    const [comment, setComment] = useState('');
    const [instance, updateInstance] = usePDF({document: <MyDoc comment={comment.trim()} order={order} data={orderDetailsList}/>});
    const [printReady, setPrintReady] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleSubmit = () => {
        updateInstance()
        setPrintReady(true)
    }
    if(!orderDetailsList?.length) return <></>
    return (
        <>
            <Button className={"float-end btn-576-float-none mx-1"} onClick={handleShow}>
                Orderbon
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Orderbon maken
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Opmerking toevoegen?</Form.Label>
                        <Form.Control value={comment} onChange={e => setComment(e.target.value)} as="textarea" placeholder="Opmerking"/>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Annuleren
                    </Button>
                    {!printReady ? <Button onClick={handleSubmit}>Aanmaken</Button> :
                        instance.loading ? <Button><Spinner animation="border" variant="light" size="sm"/></Button>
                            : <a href={instance.url} download={`${order.product_order_reference} ${order.project_code} ORDERBON.pdf`}>
                                <Button onClick={()=>setPrintReady(false)} variant={"success"}>Download</Button>
                            </a>
                    }
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default OrderBonModal;

/**
 * A component that generates a document with specific information.
 *
 * @typedef {Object} MyDoc
 * @property {string} comment - The comment for the document.
 * @property {Object} order - The order information.
 * @property {Object[]} data - The data to be displayed in the document.
 * @returns {Object} - The generated document.
 */
const MyDoc = ({comment, order, data}) => {
    return (
        <Document title={`Orderbon ${order.product_order_reference} ${order.project_name}`}>
            <Page style={styles.body}>
                <View style={{flexDirection: "row", justifyContent: "space-between", marginBottom: 10}}>
                    <Image style={styles.yconLogo} src={Logo}/>
                    <View style={{width: 200}}>
                        <Text style={styles.textSmallRightBold}>Nordomatic Belgium BV</Text>
                        <Text style={styles.textSmallRight}>Herentalsesteenweg 81A</Text>
                        <Text style={styles.textSmallRight}>2280 Grobbendonk, Belgium</Text>
                        <Text style={styles.textSmallRight}>BTW nr: BE0826403970</Text>
                        <Text style={styles.textSmallRight}>IBAN: BE76 0016 1290 6195</Text>
                        <Text style={styles.textSmallRight}>(+32) 1449 0280</Text>
                        <Text style={styles.textSmallRight}>sm-be-info@nordomatic.com</Text>
                    </View>
                </View>
                <View style={{
                    borderTop: "1px solid black",
                    borderBottom: "1px solid black",
                    paddingVertical: 2,
                    alignContent: "center",
                    justifyContent: "center",
                    marginBottom: 20
                }}>
                    <Text style={styles.pageTitle}> INKOOPORDER - {order.product_order_reference} / {order.project_code} </Text>
                </View>
                <View>
                    <View style={{marginBottom: 15}}>
                        <View>
                            <Text style={styles.title}>AFLEVERADRES</Text>
                            <View style={{flexDirection: "row"}}>
                                <View style={{marginRight: 15}}>
                                    <Text style={styles.textSmallRefr}>REFR</Text>
                                    <Text style={styles.textSmallAdres}>ADRES</Text>
                                    <Text style={styles.textSmall}>CONTACT</Text>
                                    <Text style={styles.textSmall}>DATUM</Text>
                                    <Text style={styles.textSmall}>PRIO</Text>
                                </View>
                                <View style={{marginRight: 15}}>
                                    <Text style={styles.textSmallRefr}>:</Text>
                                    <Text style={styles.textSmallAdres}>:</Text>
                                    <Text style={styles.textSmall}>:</Text>
                                    <Text style={styles.textSmall}>:</Text>
                                    <Text style={styles.textSmall}>:</Text>
                                </View>
                                <View>
                                    <Text style={styles.textSmall}>{order.product_order_reference}</Text>
                                    <Text style={styles.textSmall}>{order.project_name}</Text>
                                    <Text style={styles.textSmall}>{order?.product_order_customer}</Text>
                                    <Text style={styles.textSmall}>{order?.product_order_address}</Text>
                                    <Text style={styles.textSmall}>{`${order?.product_order_postcode} ${order?.product_order_city}`}</Text>
                                    <Text style={styles.textSmall}>{order?.product_order_contact}</Text>
                                    <Text style={styles.textSmall}>{moment().format("DD-MM-YYYY")}</Text>
                                    <Text style={styles.textSmall}>{order.product_order_priority}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View>
                        <Text style={styles.title}>LEVERANCIER</Text>
                        <View style={{flexDirection: "row"}}>
                            <View style={{marginRight: 15}}>
                                <Text style={styles.textSmall}>NAAM</Text>
                                <Text style={styles.textSmall}>ADRES</Text>
                                <Text style={styles.textSmall}>EMAIL</Text>
                            </View>
                            <View style={{marginRight: 15}}>
                                <Text style={styles.textSmall}>:</Text>
                                <Text style={styles.textSmall}>:</Text>
                                <Text style={styles.textSmall}>:</Text>
                            </View>
                            <View>
                                <Text style={styles.textSmall}>{order.product_supplier_name}</Text>
                                <Text
                                    style={styles.textSmall}>{`${order.product_supplier_address || ""} ${order.product_supplier_postcode || ""} ${order.product_supplier_city || ""} ${order.product_supplier_country || ""}`.trim()}</Text>
                                <Text style={styles.textSmall}>{order.product_supplier_email}</Text>
                            </View>
                        </View>
                    </View>
                </View>
                {comment ? <View style={{marginTop: 15}}>
                    <Text style={styles.title}> OPMERKING</Text>
                    <Text style={styles.textSmall}>{comment}</Text>
                </View> : null}
                <View style={{marginTop: 30}}>
                    {data ? <Table style={styles.tableStyle}
                                   data={[...data, {
                                       product_order_detail_amount: "Totaal",
                                       product_order_detail_price_total: data.reduce((a, b) => ({
                                           product_order_detail_price_total: parseFloat(a.product_order_detail_price_total) + parseFloat(b.product_order_detail_price_total)
                                       })).product_order_detail_price_total,
                                   }]}>
                        <TableHeader style={styles.tableStyle} includeTopBorder={false} includeRightBorder={false}
                                     includeLeftBorder={false}>
                            <TableCell style={styles.tableStyleHeader} weighting={0.25}>
                                PRODUCT
                            </TableCell>
                            <TableCell style={styles.tableStyleHeader} weighting={0.35}>
                                BESCHRIJVING
                            </TableCell>
                            <TableCell style={styles.tableStyleHeader} weighting={0.15}>
                                PRIJS
                            </TableCell>
                            <TableCell style={styles.tableStyleHeader} weighting={0.1}>
                                QTY
                            </TableCell>
                            <TableCell style={styles.tableStyleHeader} weighting={0.15}>
                                TOTAL
                            </TableCell>
                        </TableHeader>
                        <TableBody includeTopBorder={false} includeRightBorder={false} includeLeftBorder={false} includeBottomBorder={false}>
                            <DataTableCell style={styles.tableStyleCell}
                                           weighting={0.25}
                                           getContent={(r) => r.product_name === "DIV" ? r.product_order_detail_comment : r.product_name}
                            />
                            <DataTableCell style={styles.tableStyleCell}
                                           weighting={0.35}
                                           getContent={(r) => r.product_description}
                            />
                            <DataTableCell style={styles.tableStyleCell}
                                           weighting={0.15}
                                           getContent={(r) => r.product_order_detail_price_per_unit && euroFormat(r.product_order_detail_price_per_unit)}
                            />
                            <DataTableCell style={styles.tableStyleCell}
                                           weighting={0.1}
                                           getContent={(r) => r.product_order_detail_amount}
                            />
                            <DataTableCell style={styles.tableStyleCell}
                                           weighting={0.15}
                                           getContent={(r) => euroFormat(r.product_order_detail_price_total)}
                            />
                        </TableBody>
                    </Table> : null}
                </View>
            </Page>
        </Document>
    )
};

Font.register({
    family: 'Roboto', fonts: [
        {src: RobotoRegular}, // font-style: normal, font-weight: normal
        {src: RobotoItalic, fontStyle: 'italic'},
        {src: RobotoBoldItalic, fontStyle: 'italic-bold'},
        {src: RobotoBold, fontStyle: 'bold'},
    ]
});

Font.registerHyphenationCallback(word => [word]);

/**
 * @typedef {Object} Styles
 * @property {Object} body - Style for the body element.
 * @property {Number} body.paddingTop - Top padding for the body element.
 * @property {Number} body.paddingBottom - Bottom padding for the body element.
 * @property {Number} body.paddingHorizontal - Horizontal padding for the body element.
 * @property {Number} body.fontSize - Font size for the body element.
 * @property {Object} pageTitle - Style for the page title element.
 * @property {String} pageTitle.fontFamily - Font family for the page title element.
 * @property {String} pageTitle.fontStyle - Font style for the page title element.
 * @property {Number} pageTitle.fontSize - Font size for the page title element.
 * @property {String} pageTitle.textAlign - Text alignment for the page title element.
 * @property {Object} title - Style for the title element.
 * @property {String} title.fontFamily - Font family for the title element.
 * @property {String} title.fontStyle - Font style for the title element.
 * @property {Number} title.fontSize - Font size for the title element.
 * @property {Number} title.marginBottom - Bottom margin for the title element.
 * @property {Object} titleRight - Style for the right-aligned title element.
 * @property {String} titleRight.fontFamily - Font family for the right-aligned title element.
 * @property {String} titleRight.fontStyle - Font style for the right-aligned title element.
 * @property {Number} titleRight.fontSize - Font size for the right-aligned title element.
 * @property {String} titleRight.textAlign - Text alignment for the right-aligned title element.
 * @property {Number} titleRight.marginBottom - Bottom margin for the right-aligned title element.
 * @property {Object} subTitle - Style for the subtitle element.
 * @property {String} subTitle.fontFamily - Font family for the subtitle element.
 * @property {String} subTitle.fontStyle - Font style for the subtitle element.
 * @property {Number} subTitle.fontSize - Font size for the subtitle element.
 * @property {String} subTitle.textAlign - Text alignment for the subtitle element.
 * @property {Object} textSmall - Style for the small text element.
 * @property {String} textSmall.fontFamily - Font family for the small text element.
 * @property {Number} textSmall.fontSize - Font size for the small text element.
 * @property {Number} textSmall.lineHeight - Line height for the small text element.
 * @property {Object} textSmallRight - Style for the right-aligned small text element.
 * @property {String} textSmallRight.fontFamily - Font family for the right-aligned small text element.
 * @property {Number} textSmallRight.fontSize - Font size for the right-aligned small text element.
 * @property {String} textSmallRight.textAlign - Text alignment for the right-aligned small text element.
 * @property {Number} textSmallRight.lineHeight - Line height for the right-aligned small text element.
 * @property {Object} textSmallRightBold - Style for the bold, right-aligned small text element.
 * @property {String} textSmallRightBold.fontFamily - Font family for the bold, right-aligned small text element.
 * @property {String} textSmallRightBold.fontStyle - Font style for the bold, right-aligned small text element.
 * @property {Number} textSmallRightBold.fontSize - Font size for the bold, right-aligned small text element.
 * @property {String} textSmallRightBold.textAlign - Text alignment for the bold, right-aligned small text element.
 * @property {Number} textSmallRightBold.lineHeight - Line height for the bold, right-aligned small text element.
 * @property {Object} textSmallRefr - Style for the small text element with increased line height.
 * @property {String} textSmallRefr.fontFamily - Font family for the small text element with increased line height.
 * @property {Number} textSmallRefr.fontSize - Font size for the small text element with increased line height.
 * @property {String} textSmallRefr.textAlign - Text alignment for the small text element with increased line height.
 * @property {Number} textSmallRefr.lineHeight - Line height for the small text element with increased line height.
 * @property {Object} textSmallAdres - Style for the small text element with extra increased line height.
 * @property {String} textSmallAdres.fontFamily - Font family for the small text element with extra increased line height.
 * @property {Number} textSmallAdres.fontSize - Font size for the small text element with extra increased line height.
 * @property {String} textSmallAdres.textAlign - Text alignment for the small text element with extra increased line height.
 * @property {Number} textSmallAdres.lineHeight - Line height for the small text element with extra increased line height.
 * @property {Object} tableStyle - Style for the table element.
 * @property {Number} tableStyle.fontSize - Font size for the table element.
 * @property {String} tableStyle.justifyContent - Justify content for the table element.
 * @property {Number} tableStyle.marginBottom - Bottom margin for the table element.
 * @property {Object} tableStyleCell - Style for the table cell element.
 * @property {Number} tableStyleCell.fontSize - Font size for the table cell element.
 * @property {String} tableStyleCell.justifyContent - Justify content for the table cell element.
 * @property {Number} tableStyleCell.padding - Padding for the table cell element.
 * @property {String} tableStyleCell.borderRightColor - Border right color for the table cell element.
 * @property {String} tableStyleCell.borderBottom - Bottom border for the table cell element.
 * @property {Object} tableStyleHeader - Style for the table header element.
 * @property {Number} tableStyleHeader.fontSize - Font size for the table header element.
 * @property {String} tableStyleHeader.justifyContent - Justify content for the table header element.
 * @property {Number} tableStyleHeader.padding - Padding for the table header element.
 * @property {String} tableStyleHeader.borderRightColor - Border right color for the table header element.
 * @property {Object} yconLogo - Style for the logo element.
 * @property {Number} yconLogo.top - Top position for the logo element.
 * @property {Number} yconLogo.width - Width for the logo element.
 * @property {String} yconLogo.height - Height for the logo element.
 */
const styles = StyleSheet.create(
    {
        body: {
            paddingTop: 35,
            paddingBottom: 65,
            paddingHorizontal: 40,
            fontSize: 10
        },
        pageTitle: {
            fontFamily: "Roboto",
            fontStyle: "bold",
            fontSize: 16,
            textAlign: "center",
        },
        title: {
            fontFamily: "Roboto",
            fontStyle: "bold",
            fontSize: 12,
            marginBottom: 5
        },
        titleRight: {
            fontFamily: "Roboto",
            fontStyle: "bold",
            fontSize: 12,
            textAlign: "right",
            marginBottom: 5
        },
        subTitle: {
            fontFamily: "Roboto",
            fontStyle: "bold",
            fontSize: 11,
            textAlign: "right",
        },
        textSmall: {
            fontFamily: "Roboto",
            fontSize: 9,
            lineHeight: 1.3,
        },
        textSmallRight: {
            fontFamily: "Roboto",
            fontSize: 9,
            textAlign: "right",
            lineHeight: 1.3,
        },
        textSmallRightBold: {
            fontFamily: "Roboto",
            fontStyle: "bold",
            fontSize: 9,
            textAlign: "right",
            lineHeight: 1.3,
        },
        textSmallRefr: {
            fontFamily: "Roboto",
            fontSize: 9,
            textAlign: "right",
            lineHeight: 2.6,
        },
        textSmallAdres: {
            fontFamily: "Roboto",
            fontSize: 9,
            textAlign: "right",
            lineHeight: 3.9,
        },
        tableStyle: {
            fontSize: 9,
            justifyContent: "start",
            marginBottom: 20,
        },
        tableStyleCell: {
            fontSize: 9,
            justifyContent: "start",
            padding: 5,
            borderRightColor: "#ffffff",
            borderBottom: "1 solid #cccccc"
        },
        tableStyleHeader: {
            fontSize: 9,
            justifyContent: "start",
            padding: 5,
            borderRightColor: "#ffffff"
        },
        yconLogo: {top: 0, width: "auto", height: 50, marginBottom: 50}

    }
)